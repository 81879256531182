var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-area" }, [
    _c("div", { staticClass: "first-screen" }, [
      _c("div", { staticClass: "pool-area" }, [
        _c("div", { staticClass: "text-area" }, [
          _c("div", { staticClass: "h2" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.infinity_pool")) +
                "\n          "
            ),
            _c("span", { staticClass: "header-bubble" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("landing.for_your")) +
                  "\n          "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("h1", [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.crypto_endeavors")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "after-header" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.join_text")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons-area" },
            [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.user === undefined || _vm.user === null,
                      expression: "user === undefined || user === null",
                    },
                  ],
                  staticClass: "btn btn-outline pink",
                  attrs: {
                    type: "button",
                    "aria-label": _vm.$t("common.connect_wallet"),
                  },
                  on: { click: _vm.openModalWallets },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("common.connect_wallet")) +
                        "\n            "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.user !== undefined && _vm.user !== null,
                      expression: "user !== undefined && user !== null",
                    },
                  ],
                  staticClass: "btn btn-outline pink",
                  attrs: {
                    to: _vm.localePath("/liquidity"),
                    "aria-label": _vm.$t("common.liquidity_pools"),
                  },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("common.liquidity_pools")) +
                        "\n            "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  staticClass: "btn btn-outline cyan",
                  attrs: {
                    to: _vm.localePath("/swap"),
                    type: "button",
                    "aria-label": _vm.$t("landing.trade_now"),
                  },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("landing.trade_now")) +
                        "\n            "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pink-cloud" }, [_c("PinkOval")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "cyan-cloud" }, [_c("CyanOval")], 1),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "image-area" }, [
          _vm.darkTheme
            ? _c("div", { staticClass: "image-main" }, [
                _c(
                  "picture",
                  { staticClass: "picture" },
                  [
                    _c("source", {
                      attrs: {
                        srcset: "/img/new/main/defi_coins_dark.webp",
                        type: "image/webp",
                      },
                    }),
                    _vm._v(" "),
                    _c("nuxt-img", {
                      attrs: {
                        "legacy-format": "png",
                        fit: "inside",
                        src: "/img/new/main/defi_coins_dark.png",
                        alt: "Defi Pool Finance is a Web3 agregator for earn, trade and manage tokens liquidity",
                        title: "Defi Pool Finance Web3 future instrument",
                        loading: "lazy",
                        placeholder: "",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _c("div", { staticClass: "image-main" }, [
                _c(
                  "picture",
                  { staticClass: "picture" },
                  [
                    _c("source", {
                      attrs: {
                        srcset: "/img/new/main/defi_coins_light.webp",
                        type: "image/webp",
                      },
                    }),
                    _vm._v(" "),
                    _c("nuxt-img", {
                      attrs: {
                        "legacy-format": "png",
                        fit: "inside",
                        src: "/img/new/main/defi_coins_light.png",
                        alt: "Defi Pool Finance is a Web3 agregator for earn, trade and manage tokens liquidity",
                        title: "Defi Pool Finance Web3 future instrument",
                        loading: "lazy",
                        placeholder: "",
                      },
                    }),
                  ],
                  1
                ),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "semi-box" }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "stats-line" }, [
      _c("div", { staticClass: "stats-area" }, [
        _c("div", { staticClass: "stat" }, [
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.$t("landing.atv")) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h5" }, [
            _vm._v("\n          $83B+\n        "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-line" }),
        _vm._v(" "),
        _c("div", { staticClass: "stat" }, [
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.$t("landing.lps")) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h5" }, [
            _vm._v("\n          72K+\n        "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-line xs-hidden" }),
        _vm._v(" "),
        _c("div", { staticClass: "stat" }, [
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.$t("landing.att")) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h5" }, [
            _vm._v("\n          29M+\n        "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-line" }),
        _vm._v(" "),
        _c("div", { staticClass: "stat" }, [
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.defi_integrations")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h5" }, [
            _vm._v("\n          200+\n        "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "socials-area" }, [
        _vm.contacts.discord !== undefined &&
        _vm.contacts.discord !== null &&
        _vm.contacts.discord.length > 0
          ? _c("div", { staticClass: "social" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.contacts.discord,
                    rel: "nofollow norefferer noopener",
                    target: "_blank",
                  },
                },
                [
                  _c("DiscordLogo"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("landing.group")) +
                      "\n        "
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "divider-line" }),
        _vm._v(" "),
        _vm.contacts.discord !== undefined &&
        _vm.contacts.discord !== null &&
        _vm.contacts.discord.length > 0
          ? _c("div", { staticClass: "social" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.contacts.twitter,
                    rel: "nofollow norefferer noopener",
                    target: "_blank",
                  },
                },
                [
                  _c("TwitterLogo"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("landing.news")) +
                      "\n        "
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "divider-line" }),
        _vm._v(" "),
        _vm.contacts.discord !== undefined &&
        _vm.contacts.discord !== null &&
        _vm.contacts.discord.length > 0
          ? _c("div", { staticClass: "social" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.contacts.telegram,
                    rel: "nofollow norefferer noopener",
                    target: "_blank",
                  },
                },
                [
                  _c("TelegramLogo"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("landing.support")) +
                      "\n        "
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "second-screen" }, [
      _c("div", { staticClass: "mobile-hidden image-area" }, [
        _c("div", { staticClass: "image" }, [
          _vm.darkTheme
            ? _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/defi_swap_dark.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/defi_swap_dark.png",
                      alt: "Defi Pool Finance system exchange, trade, earn",
                      title: "Defi Pool Finance system",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              )
            : _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/defi_swap_light.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/defi_swap_light.png",
                      alt: "Defi Pool Finance system exchange, trade, earn",
                      title: "Defi Pool Finance system",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "semi-box" }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-area" }, [
        _c("div", { staticClass: "h4" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("landing.dp_invites_header")) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mobile-visible image" }, [
          _vm.darkTheme
            ? _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/defi_swap_dark.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/defi_swap_dark.png",
                      alt: "Defi Pool Finance system exchange, trade, earn",
                      title: "Defi Pool Finance system",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              )
            : _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/defi_swap_light.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/defi_swap_light.png",
                      alt: "Defi Pool Finance system exchange, trade, earn",
                      title: "Defi Pool Finance system",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "after-header" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("landing.dp_invites_text")) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons-area" },
          [
            _c(
              "nuxt-link",
              {
                staticClass: "btn cyan",
                attrs: {
                  to: _vm.localePath("/swap"),
                  "aria-label": _vm.$t("landing.trade_now"),
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("landing.trade_now")) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.darkTheme
          ? _c(
              "div",
              { staticClass: "pink-cloud" },
              [_c("PinkCloudCompany")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "cyan-cloud" }, [_c("CyanCloudCompany")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "semi-box" }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "third-screen" }, [
      _c("div", { staticClass: "icon-area" }, [
        _c("div", { staticClass: "image-area" }, [
          _vm.darkTheme
            ? _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/swap_dark.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/swap_dark.png",
                      alt: _vm.$t("landing.hassle_trading_header"),
                      title: "Defi Pool Finance Income",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              )
            : _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/swap_light.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/swap_light.png",
                      alt: _vm.$t("landing.hassle_trading_header"),
                      title: "Defi Pool Finance Income",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-area" }, [
          _c("div", { staticClass: "h6" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.hassle_trading_header")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.hassle_trading_text")) +
                "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cyan-cloud" }, [_c("CyanCloudIcons")], 1),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "icon-area" }, [
        _c("div", { staticClass: "image-area" }, [
          _vm.darkTheme
            ? _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/chart_dark.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/chart_dark.png",
                      alt: _vm.$t("landing.passive_income_header"),
                      title: "Defi Pool Finance Wallet",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              )
            : _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/chart_light.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/chart_light.png",
                      alt: _vm.$t("landing.passive_income_header"),
                      title: "Defi Pool Finance Wallet",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-area" }, [
          _c("div", { staticClass: "h6" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.passive_income_header")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.passive_income_text")) +
                "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cyan-cloud" }, [_c("CyanCloudIcons")], 1),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "icon-area" }, [
        _c("div", { staticClass: "image-area" }, [
          _vm.darkTheme
            ? _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/fingerprint_dark.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/fingerprint_dark.png",
                      alt: _vm.$t("landing.be_your_own_header"),
                      title: "Defi Pool Finance Savings",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              )
            : _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/fingerprint_light.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/fingerprint_light.png",
                      alt: _vm.$t("landing.be_your_own_header"),
                      title: "Defi Pool Finance Savings",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-area" }, [
          _c("div", { staticClass: "h6" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.be_your_own_header")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.be_your_own_text")) +
                "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cyan-cloud" }, [_c("CyanCloudIcons")], 1),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "floatie-screen" }, [
      _c("div", { staticClass: "text-area" }, [
        _c("div", { staticClass: "h2" }, [_vm._v("\n        $DFP\n      ")]),
        _vm._v(" "),
        _c("p", { staticClass: "desc" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("landing.floatie_text")) + "\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons-area" },
          [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.user === undefined || _vm.user === null,
                    expression: "user === undefined || user === null",
                  },
                ],
                staticClass: "btn cyan",
                attrs: {
                  type: "button",
                  "aria-label": _vm.$t("common.connect_wallet"),
                },
                on: { click: _vm.openModalWallets },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.connect_wallet")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.user !== undefined && _vm.user !== null,
                    expression: "user !== undefined && user !== null",
                  },
                ],
                staticClass: "btn cyan",
                attrs: { to: _vm.localePath("/swap") },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("landing.trade_now")) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mobile-visible image-area" }, [
          _c("div", { staticClass: "floatie" }, [
            _vm.darkTheme
              ? _c(
                  "picture",
                  { staticClass: "picture" },
                  [
                    _c("source", {
                      attrs: {
                        srcset: "/img/new/main/defi_liquidity_dark.webp",
                        type: "image/webp",
                      },
                    }),
                    _vm._v(" "),
                    _c("nuxt-img", {
                      attrs: {
                        "legacy-format": "png",
                        fit: "inside",
                        src: "/img/new/main/defi_liquidity_dark.png",
                        alt: "$DFP token, earn, trade and stake",
                        title: "Defi Pool Finance $DFP",
                        loading: "lazy",
                        placeholder: "",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "picture",
                  { staticClass: "picture" },
                  [
                    _c("source", {
                      attrs: {
                        srcset: "/img/new/main/defi_liquidity_light.webp",
                        type: "image/webp",
                      },
                    }),
                    _vm._v(" "),
                    _c("nuxt-img", {
                      attrs: {
                        "legacy-format": "png",
                        fit: "inside",
                        src: "/img/new/main/defi_liquidity_light.png",
                        alt: "$DFP token, earn, trade and stake",
                        title: "Defi Pool Finance $DFP",
                        loading: "lazy",
                        placeholder: "",
                      },
                    }),
                  ],
                  1
                ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pink-cloud" }, [_c("CloudPinkFloatie")], 1),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "stats-area" }, [
          _c("div", { staticClass: "stat" }, [
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("landing.users")) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "h5" }, [
              _vm._v(
                "\n            2.9 " +
                  _vm._s(_vm.$t("common.million")) +
                  "\n          "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "divider-line" }),
          _vm._v(" "),
          _c("div", { staticClass: "stat" }, [
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("landing.trades")) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "h5" }, [
              _vm._v(
                "\n            40 " +
                  _vm._s(_vm.$t("common.million")) +
                  "\n          "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "divider-line" }),
          _vm._v(" "),
          _c("div", { staticClass: "stat" }, [
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("landing.staked")) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "h5" }, [
              _vm._v(
                "\n            4.7 " +
                  _vm._s(_vm.$t("common.billion")) +
                  "\n          "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cyan-cloud" }, [_c("CloudCyanFloatie")], 1),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mobile-hidden image-area" }, [
        _c("div", { staticClass: "floatie" }, [
          _vm.darkTheme
            ? _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/defi_liquidity_dark.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/defi_liquidity_dark.png",
                      alt: "$DFP token, earn, trade and stake",
                      title: "Defi Pool Finance $DFP",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              )
            : _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/defi_liquidity_light.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/defi_liquidity_light.png",
                      alt: "$DFP token, earn, trade and stake",
                      title: "Defi Pool Finance $DFP",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pink-cloud" }, [_c("CloudPinkFloatie")], 1),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "banners-screen" }, [
      _c("div", { staticClass: "banner" }, [
        _c("div", { staticClass: "image-area" }, [
          _vm.darkTheme
            ? _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/balance_dark.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/balance_dark.png",
                      alt: "Tokens, pairs and staking analytics for better performance",
                      title: "Defi Pool Finance Abalytics",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              )
            : _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/balance_light.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/balance_light.png",
                      alt: "Tokens, pairs and staking analytics for better performance",
                      title: "Defi Pool Finance Abalytics",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-area" }, [
          _c("div", { staticClass: "h3" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.$t("common.analytics")) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.analytics_text")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons-area" },
            [
              _c(
                "nuxt-link",
                {
                  staticClass: "btn btn-revert pink",
                  attrs: {
                    to: _vm.localePath("/analytics"),
                    "aria-label": _vm.$t("common.more_info"),
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "icon icon-left" },
                    [_c("ChevronRight")],
                    1
                  ),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.visit_analytics")) +
                      "\n          "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "banner" }, [
        _c("div", { staticClass: "image-area" }, [
          _vm.darkTheme
            ? _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/crypto_fire_dark.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/crypto_fire_dark.png",
                      alt: "Use $DFP to win, multiply crypto assets and get rewards",
                      title: "Defi Pool Finance $DFP token",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              )
            : _c(
                "picture",
                { staticClass: "picture" },
                [
                  _c("source", {
                    attrs: {
                      srcset: "/img/new/main/crypto_fire_light.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      "legacy-format": "png",
                      fit: "inside",
                      src: "/img/new/main/crypto_fire_light.png",
                      alt: "Use $DFP to win, multiply crypto assets and get rewards",
                      title: "Defi Pool Finance $DFP token",
                      loading: "lazy",
                      placeholder: "",
                    },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-area" }, [
          _c("div", { staticClass: "h3" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.prediction_game_header")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("landing.prediction_game_text")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-area" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-revert cyan",
                attrs: { "aria-label": _vm.$t("common.more_info") },
              },
              [
                _c(
                  "span",
                  { staticClass: "icon icon-left" },
                  [_c("ChevronRight")],
                  1
                ),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.more_info")) +
                    "\n          "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "splashie-screen" }, [
      _c("div", { staticClass: "mobile-hidden image-area" }, [
        _c("div", { staticClass: "splashie" }, [
          _c(
            "picture",
            { staticClass: "picture" },
            [
              _c("source", {
                attrs: {
                  srcset: "/img/new/main/defi_tokens.webp",
                  type: "image/webp",
                },
              }),
              _vm._v(" "),
              _c("nuxt-img", {
                attrs: {
                  fit: "inside",
                  src: "/img/new/main/defi_tokens.png",
                  alt: "$DFPvote token, earn, trade and stake",
                  title: "Defi Pool Finance $DFPvote",
                  loading: "lazy",
                  placeholder: "",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.darkTheme
          ? _c(
              "div",
              { staticClass: "pink-cloud" },
              [_c("CloudPinkSplashie")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "cyan-cloud" }, [_c("CloudCyanSplashie")], 1),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-area" }, [
        _c("div", { staticClass: "h2" }, [
          _vm._v("\n        $DFPvote\n      "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "desc" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("landing.splashie_text")) + "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mobile-visible image-area" }, [
          _c("div", { staticClass: "splashie" }, [
            _c(
              "picture",
              { staticClass: "picture" },
              [
                _c("source", {
                  attrs: {
                    srcset: "/img/new/main/defi_tokens.webp",
                    type: "image/webp",
                  },
                }),
                _vm._v(" "),
                _c("nuxt-img", {
                  attrs: {
                    fit: "inside",
                    src: "/img/new/main/defi_tokens.png",
                    alt: "$DFPvote token, earn, trade and stake",
                    title: "Defi Pool Finance $DFPvote",
                    loading: "lazy",
                    placeholder: "",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.darkTheme
            ? _c(
                "div",
                { staticClass: "pink-cloud" },
                [_c("CloudPinkSplashie")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cyan-cloud" },
            [_c("CloudCyanSplashie")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-horizontal" }),
        _vm._v(" "),
        _c("div", { staticClass: "h6" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("landing.top_farms")) + "\n      "
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "cyan-cloud" }, [_c("CloudCyanFloatie")], 1),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "dive-section" }, [
      _c("div", { staticClass: "pool-image" }, [
        _c(
          "picture",
          { staticClass: "lazy-picture" },
          [
            _c("source", {
              attrs: {
                srcset: "/img/new/main/defipool_newsletter.webp",
                type: "image/webp",
              },
            }),
            _vm._v(" "),
            _c("nuxt-img", {
              attrs: {
                fit: "inside",
                src: "/img/new/main/defipool_newsletter.png",
                alt: "Dive in to Defi Pool Finance",
                title: "Subcribe to Defi Pool",
                loading: "lazy",
                placeholder: "",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-area" }, [
        _c("div", { staticClass: "header" }, [
          _vm._v("\n        " + _vm._s(_vm.$t("landing.dive_in")) + "\n      "),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("landing.connect_wallet_text")) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons-area" },
          [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.user === undefined || _vm.user === null,
                    expression: "user === undefined || user === null",
                  },
                ],
                staticClass: "connect-wallet",
                attrs: {
                  type: "button",
                  "aria-label": _vm.$t("common.connect_wallet"),
                },
                on: { click: _vm.openModalWallets },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.connect_wallet")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.user !== undefined && _vm.user !== null,
                    expression: "user !== undefined && user !== null",
                  },
                ],
                staticClass: "connect-wallet",
                attrs: { to: _vm.localePath("/swap") },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("landing.trade_now")) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("landing.no_registration")) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cloud-cyan-right" },
        [_c("CloudCyanDiveLeft")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cloud-cyan-left" },
        [_c("CloudCyanDiveRight")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "stats-area" }, [
      _c("div", { staticClass: "stat" }, [
        _c("p", { staticClass: "desc" }, [
          _vm._v("\n            RPG-BUSD LP\n          "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "h5" }, [
          _vm._v("\n            150.47%\n          "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "divider-line" }),
      _vm._v(" "),
      _c("div", { staticClass: "stat" }, [
        _c("p", { staticClass: "desc" }, [
          _vm._v("\n            SPL-BNB LP\n          "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "h5" }, [
          _vm._v("\n            159.21%\n          "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "divider-line" }),
      _vm._v(" "),
      _c("div", { staticClass: "stat" }, [
        _c("p", { staticClass: "desc" }, [
          _vm._v("\n            FLW-USDT LP\n          "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "h5" }, [
          _vm._v("\n            150.47%\n          "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }