<template>
  <div class="main-area">
    <div class="first-screen">
      <div class="pool-area">
        <div class="text-area">
          <div class="h2">
            {{ $t('landing.infinity_pool') }}
            <span class="header-bubble">
              {{ $t('landing.for_your') }}
            </span>
          </div>
          <h1>
            {{ $t('landing.crypto_endeavors') }}
          </h1>
          <p class="after-header">
            {{ $t('landing.join_text') }}
          </p>
          <div class="buttons-area">
            <button v-show="user === undefined || user === null" type="button" class="btn btn-outline pink" :aria-label="$t('common.connect_wallet')" @click="openModalWallets">
              <span class="text">
                {{ $t('common.connect_wallet') }}
              </span>
            </button>
            <nuxt-link v-show="user !== undefined && user !== null" :to="localePath('/liquidity')" class="btn btn-outline pink" :aria-label="$t('common.liquidity_pools')">
              <span class="text">
                {{ $t('common.liquidity_pools') }}
              </span>
            </nuxt-link>
            <nuxt-link :to="localePath('/swap')" type="button" class="btn btn-outline cyan" :aria-label="$t('landing.trade_now')">
              <span class="text">
                {{ $t('landing.trade_now') }}
              </span>
            </nuxt-link>
          </div>
          <div class="pink-cloud">
            <PinkOval />
          </div>
          <div class="cyan-cloud">
            <CyanOval />
          </div>
        </div>
        <div class="image-area">
          <div v-if="darkTheme" class="image-main">
            <picture class="picture">
              <source srcset="/img/new/main/defi_coins_dark.webp" type="image/webp">
              <nuxt-img
                legacy-format="png"
                fit="inside"
                src="/img/new/main/defi_coins_dark.png"
                alt="Defi Pool Finance is a Web3 agregator for earn, trade and manage tokens liquidity"
                title="Defi Pool Finance Web3 future instrument"
                loading="lazy"
                placeholder
              />
            </picture>
          </div>
          <div v-else class="image-main">
            <picture class="picture">
              <source srcset="/img/new/main/defi_coins_light.webp" type="image/webp">
              <nuxt-img
                legacy-format="png"
                fit="inside"
                src="/img/new/main/defi_coins_light.png"
                alt="Defi Pool Finance is a Web3 agregator for earn, trade and manage tokens liquidity"
                title="Defi Pool Finance Web3 future instrument"
                loading="lazy"
                placeholder
              />
            </picture>
          </div>
          <div class="semi-box"></div>
        </div>
      </div>
    </div>
    <div class="stats-line">
      <div class="stats-area">
        <div class="stat">
          <p class="desc">
            {{ $t('landing.atv') }}
          </p>
          <div class="h5">
            $83B+
          </div>
        </div>
        <div class="divider-line"></div>
        <div class="stat">
          <p class="desc">
            {{ $t('landing.lps') }}
          </p>
          <div class="h5">
            72K+
          </div>
        </div>
        <div class="divider-line xs-hidden"></div>
        <div class="stat">
          <p class="desc">
            {{ $t('landing.att') }}
          </p>
          <div class="h5">
            29M+
          </div>
        </div>
        <div class="divider-line"></div>
        <div class="stat">
          <p class="desc">
            {{ $t('landing.defi_integrations') }}
          </p>
          <div class="h5">
            200+
          </div>
        </div>
      </div>
      <div class="socials-area">
        <div v-if="contacts.discord !== undefined && contacts.discord !== null && contacts.discord.length > 0" class="social">
          <a :href="contacts.discord" rel="nofollow norefferer noopener" target="_blank">
            <DiscordLogo />
            {{ $t('landing.group') }}
          </a>
        </div>
        <div class="divider-line"></div>
        <div v-if="contacts.discord !== undefined && contacts.discord !== null && contacts.discord.length > 0" class="social">
          <a :href="contacts.twitter" rel="nofollow norefferer noopener" target="_blank">
            <TwitterLogo />
            {{ $t('landing.news') }}
          </a>
        </div>
        <div class="divider-line"></div>
        <div v-if="contacts.discord !== undefined && contacts.discord !== null && contacts.discord.length > 0" class="social">
          <a :href="contacts.telegram" rel="nofollow norefferer noopener" target="_blank">
            <TelegramLogo />
            {{ $t('landing.support') }}
          </a>
        </div>
      </div>
    </div>
    <div class="second-screen">
      <div class="mobile-hidden image-area">
        <div class="image">
          <picture v-if="darkTheme" class="picture">
            <source srcset="/img/new/main/defi_swap_dark.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/defi_swap_dark.png"
              alt="Defi Pool Finance system exchange, trade, earn"
              title="Defi Pool Finance system"
              loading="lazy"
              placeholder
            />
          </picture>
          <picture v-else class="picture">
            <source srcset="/img/new/main/defi_swap_light.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/defi_swap_light.png"
              alt="Defi Pool Finance system exchange, trade, earn"
              title="Defi Pool Finance system"
              loading="lazy"
              placeholder
            />
          </picture>
        </div>
        <div class="semi-box"></div>
      </div>
      <div class="text-area">
        <div class="h4">
          {{ $t('landing.dp_invites_header') }}
        </div>
        <div class="mobile-visible image">
          <picture v-if="darkTheme" class="picture">
            <source srcset="/img/new/main/defi_swap_dark.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/defi_swap_dark.png"
              alt="Defi Pool Finance system exchange, trade, earn"
              title="Defi Pool Finance system"
              loading="lazy"
              placeholder
            />
          </picture>
          <picture v-else class="picture">
            <source srcset="/img/new/main/defi_swap_light.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/defi_swap_light.png"
              alt="Defi Pool Finance system exchange, trade, earn"
              title="Defi Pool Finance system"
              loading="lazy"
              placeholder
            />
          </picture>
        </div>
        <p class="after-header">
          {{ $t('landing.dp_invites_text') }}
        </p>
        <div class="buttons-area">
          <nuxt-link :to="localePath('/swap')" class="btn cyan" :aria-label="$t('landing.trade_now')">
            {{ $t('landing.trade_now') }}
          </nuxt-link>
        </div>
        <div v-if="darkTheme" class="pink-cloud">
          <PinkCloudCompany />
        </div>
        <div class="cyan-cloud">
          <CyanCloudCompany />
        </div>
        <div class="semi-box"></div>
      </div>
    </div>
    <div class="third-screen">
      <div class="icon-area">
        <div class="image-area">
          <picture v-if="darkTheme" class="picture">
            <source srcset="/img/new/main/swap_dark.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/swap_dark.png"
              :alt="$t('landing.hassle_trading_header')"
              title="Defi Pool Finance Income"
              loading="lazy"
              placeholder
            />
          </picture>
          <picture v-else class="picture">
            <source srcset="/img/new/main/swap_light.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/swap_light.png"
              :alt="$t('landing.hassle_trading_header')"
              title="Defi Pool Finance Income"
              loading="lazy"
              placeholder
            />
          </picture>
        </div>
        <div class="text-area">
          <div class="h6">
            {{ $t('landing.hassle_trading_header') }}
          </div>
          <p class="desc">
            {{ $t('landing.hassle_trading_text') }}
          </p>
        </div>
        <div class="cyan-cloud">
          <CyanCloudIcons />
        </div>
      </div>
      <div class="icon-area">
        <div class="image-area">
          <picture v-if="darkTheme" class="picture">
            <source srcset="/img/new/main/chart_dark.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/chart_dark.png"
              :alt="$t('landing.passive_income_header')"
              title="Defi Pool Finance Wallet"
              loading="lazy"
              placeholder
            />
          </picture>
          <picture v-else class="picture">
            <source srcset="/img/new/main/chart_light.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/chart_light.png"
              :alt="$t('landing.passive_income_header')"
              title="Defi Pool Finance Wallet"
              loading="lazy"
              placeholder
            />
          </picture>
        </div>
        <div class="text-area">
          <div class="h6">
            {{ $t('landing.passive_income_header') }}
          </div>
          <p class="desc">
            {{ $t('landing.passive_income_text') }}
          </p>
        </div>
        <div class="cyan-cloud">
          <CyanCloudIcons />
        </div>
      </div>
      <div class="icon-area">
        <div class="image-area">
          <picture v-if="darkTheme" class="picture">
            <source srcset="/img/new/main/fingerprint_dark.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/fingerprint_dark.png"
              :alt="$t('landing.be_your_own_header')"
              title="Defi Pool Finance Savings"
              loading="lazy"
              placeholder
            />
          </picture>
          <picture v-else class="picture">
            <source srcset="/img/new/main/fingerprint_light.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/fingerprint_light.png"
              :alt="$t('landing.be_your_own_header')"
              title="Defi Pool Finance Savings"
              loading="lazy"
              placeholder
            />
          </picture>
        </div>
        <div class="text-area">
          <div class="h6">
            {{ $t('landing.be_your_own_header') }}
          </div>
          <p class="desc">
            {{ $t('landing.be_your_own_text') }}
          </p>
        </div>
        <div class="cyan-cloud">
          <CyanCloudIcons />
        </div>
      </div>
    </div>
    <div class="floatie-screen">
      <div class="text-area">
        <div class="h2">
          $DFP
        </div>
        <p class="desc">
          {{ $t('landing.floatie_text') }}
        </p>
        <div class="buttons-area">
          <button v-show="user === undefined || user === null" type="button" class="btn cyan" :aria-label="$t('common.connect_wallet')" @click="openModalWallets">
            {{ $t('common.connect_wallet') }}
          </button>
          <nuxt-link v-show="user !== undefined && user !== null" :to="localePath('/swap')" class="btn cyan">
            {{ $t('landing.trade_now') }}
          </nuxt-link>
        </div>
        <div class="mobile-visible image-area">
          <div class="floatie">
            <picture v-if="darkTheme" class="picture">
              <source srcset="/img/new/main/defi_liquidity_dark.webp" type="image/webp">
              <nuxt-img
                legacy-format="png"
                fit="inside"
                src="/img/new/main/defi_liquidity_dark.png"
                alt="$DFP token, earn, trade and stake"
                title="Defi Pool Finance $DFP"
                loading="lazy"
                placeholder
              />
            </picture>
            <picture v-else class="picture">
              <source srcset="/img/new/main/defi_liquidity_light.webp" type="image/webp">
              <nuxt-img
                legacy-format="png"
                fit="inside"
                src="/img/new/main/defi_liquidity_light.png"
                alt="$DFP token, earn, trade and stake"
                title="Defi Pool Finance $DFP"
                loading="lazy"
                placeholder
              />
            </picture>
          </div>
          <div class="pink-cloud">
            <CloudPinkFloatie />
          </div>
        </div>
        <div class="stats-area">
          <div class="stat">
            <p class="desc">
              {{ $t('landing.users') }}
            </p>
            <div class="h5">
              2.9 {{ $t('common.million') }}
            </div>
          </div>
          <div class="divider-line"></div>
          <div class="stat">
            <p class="desc">
              {{ $t('landing.trades') }}
            </p>
            <div class="h5">
              40 {{ $t('common.million') }}
            </div>
          </div>
          <div class="divider-line"></div>
          <div class="stat">
            <p class="desc">
              {{ $t('landing.staked') }}
            </p>
            <div class="h5">
              4.7 {{ $t('common.billion') }}
            </div>
          </div>
        </div>
        <div class="cyan-cloud">
          <CloudCyanFloatie />
        </div>
      </div>
      <div class="mobile-hidden image-area">
        <div class="floatie">
          <picture v-if="darkTheme" class="picture">
            <source srcset="/img/new/main/defi_liquidity_dark.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/defi_liquidity_dark.png"
              alt="$DFP token, earn, trade and stake"
              title="Defi Pool Finance $DFP"
              loading="lazy"
              placeholder
            />
          </picture>
          <picture v-else class="picture">
            <source srcset="/img/new/main/defi_liquidity_light.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/defi_liquidity_light.png"
              alt="$DFP token, earn, trade and stake"
              title="Defi Pool Finance $DFP"
              loading="lazy"
              placeholder
            />
          </picture>
        </div>
        <div class="pink-cloud">
          <CloudPinkFloatie />
        </div>
      </div>
    </div>
    <div class="banners-screen">
      <div class="banner">
        <div class="image-area">
          <picture v-if="darkTheme" class="picture">
            <source srcset="/img/new/main/balance_dark.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/balance_dark.png"
              alt="Tokens, pairs and staking analytics for better performance"
              title="Defi Pool Finance Abalytics"
              loading="lazy"
              placeholder
            />
          </picture>
          <picture v-else class="picture">
            <source srcset="/img/new/main/balance_light.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/balance_light.png"
              alt="Tokens, pairs and staking analytics for better performance"
              title="Defi Pool Finance Abalytics"
              loading="lazy"
              placeholder
            />
          </picture>
        </div>
        <div class="text-area">
          <div class="h3">
            {{ $t('common.analytics') }}
          </div>
          <p class="desc">
            {{ $t('landing.analytics_text') }}
          </p>
          <div class="buttons-area">
            <nuxt-link :to="localePath('/analytics')" class="btn btn-revert pink" :aria-label="$t('common.more_info')">
              <span class="icon icon-left">
                <ChevronRight />
              </span>
              {{ $t('common.visit_analytics') }}
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="image-area">
          <picture v-if="darkTheme" class="picture">
            <source srcset="/img/new/main/crypto_fire_dark.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/crypto_fire_dark.png"
              alt="Use $DFP to win, multiply crypto assets and get rewards"
              title="Defi Pool Finance $DFP token"
              loading="lazy"
              placeholder
            />
          </picture>
          <picture v-else class="picture">
            <source srcset="/img/new/main/crypto_fire_light.webp" type="image/webp">
            <nuxt-img
              legacy-format="png"
              fit="inside"
              src="/img/new/main/crypto_fire_light.png"
              alt="Use $DFP to win, multiply crypto assets and get rewards"
              title="Defi Pool Finance $DFP token"
              loading="lazy"
              placeholder
            />
          </picture>
        </div>
        <div class="text-area">
          <div class="h3">
            {{ $t('landing.prediction_game_header') }}
          </div>
          <p class="desc">
            {{ $t('landing.prediction_game_text') }}
          </p>
          <div class="buttons-area">
            <button class="btn btn-revert cyan" :aria-label="$t('common.more_info')">
              <span class="icon icon-left">
                <ChevronRight />
              </span>
              {{ $t('common.more_info') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="splashie-screen">
      <div class="mobile-hidden image-area">
        <div class="splashie">
          <picture class="picture">
            <source srcset="/img/new/main/defi_tokens.webp" type="image/webp">
            <nuxt-img
              fit="inside"
              src="/img/new/main/defi_tokens.png"
              alt="$DFPvote token, earn, trade and stake"
              title="Defi Pool Finance $DFPvote"
              loading="lazy"
              placeholder
            />
          </picture>
        </div>
        <div v-if="darkTheme" class="pink-cloud">
          <CloudPinkSplashie />
        </div>
        <div class="cyan-cloud">
          <CloudCyanSplashie />
        </div>
      </div>
      <div class="text-area">
        <div class="h2">
          $DFPvote
        </div>
        <p class="desc">
          {{ $t('landing.splashie_text') }}
        </p>
        <div class="mobile-visible image-area">
          <div class="splashie">
            <picture class="picture">
              <source srcset="/img/new/main/defi_tokens.webp" type="image/webp">
              <nuxt-img
                fit="inside"
                src="/img/new/main/defi_tokens.png"
                alt="$DFPvote token, earn, trade and stake"
                title="Defi Pool Finance $DFPvote"
                loading="lazy"
                placeholder
              />
            </picture>
          </div>
          <div v-if="darkTheme" class="pink-cloud">
            <CloudPinkSplashie />
          </div>
          <div class="cyan-cloud">
            <CloudCyanSplashie />
          </div>
        </div>
        <div class="divider-horizontal"></div>
        <div class="h6">
          {{ $t('landing.top_farms') }}
        </div>
        <div class="stats-area">
          <div class="stat">
            <p class="desc">
              RPG-BUSD LP
            </p>
            <div class="h5">
              150.47%
            </div>
          </div>
          <div class="divider-line"></div>
          <div class="stat">
            <p class="desc">
              SPL-BNB LP
            </p>
            <div class="h5">
              159.21%
            </div>
          </div>
          <div class="divider-line"></div>
          <div class="stat">
            <p class="desc">
              FLW-USDT LP
            </p>
            <div class="h5">
              150.47%
            </div>
          </div>
        </div>
        <div class="cyan-cloud">
          <CloudCyanFloatie />
        </div>
      </div>
    </div>
    <div class="dive-section">
      <div class="pool-image">
        <picture class="lazy-picture">
          <source srcset="/img/new/main/defipool_newsletter.webp" type="image/webp">
          <nuxt-img
            fit="inside"
            src="/img/new/main/defipool_newsletter.png"
            alt="Dive in to Defi Pool Finance"
            title="Subcribe to Defi Pool"
            loading="lazy"
            placeholder
          />
        </picture>
      </div>
      <div class="text-area">
        <div class="header">
          {{ $t('landing.dive_in') }}
        </div>
        <p>
          {{ $t('landing.connect_wallet_text') }}
        </p>
        <div class="buttons-area">
          <button v-show="user === undefined || user === null" type="button" class="connect-wallet" :aria-label="$t('common.connect_wallet')" @click="openModalWallets">
            {{ $t('common.connect_wallet') }}
          </button>
          <nuxt-link v-show="user !== undefined && user !== null" :to="localePath('/swap')" class="connect-wallet">
            {{ $t('landing.trade_now') }}
          </nuxt-link>
        </div>
        <p>
          {{ $t('landing.no_registration') }}
        </p>
      </div>
      <div class="cloud-cyan-right">
        <CloudCyanDiveLeft />
      </div>
      <div class="cloud-cyan-left">
        <CloudCyanDiveRight />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  hydrateWhenIdle,
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  name: 'MainPageComponent',
  components: {
    ChevronRight: hydrateWhenVisible(() => import('~/components/svg/new/ChevronRight')),
    DiscordLogo: hydrateWhenIdle(() => import('~/components/svg/new/DiscordLogo')),
    TelegramLogo: hydrateWhenIdle(() => import('~/components/svg/new/TelegramLogo')),
    CyanOval: hydrateWhenVisible(() => import('~/components/svg/new/clouds/CyanOval')),
    PinkOval: hydrateWhenVisible(() => import('~/components/svg/new/clouds/PinkOval')),
    PinkCloudCompany: hydrateWhenVisible(() => import('~/components/svg/new/clouds/PinkCloudCompany')),
    CyanCloudCompany: hydrateWhenVisible(() => import('~/components/svg/new/clouds/CyanCloudCompany')),
    CyanCloudIcons: hydrateWhenVisible(() => import('~/components/svg/new/clouds/CyanCloudIcons')),
    CloudCyanFloatie: hydrateWhenVisible(() => import('~/components/svg/new/clouds/CloudCyanFloatie')),
    CloudPinkFloatie: hydrateWhenVisible(() => import('~/components/svg/new/clouds/CloudPinkFloatie')),
    CloudCyanSplashie: hydrateWhenVisible(() => import('~/components/svg/new/clouds/CloudCyanSplashie')),
    CloudPinkSplashie: hydrateWhenVisible(() => import('~/components/svg/new/clouds/CloudPinkSplashie')),
    CloudCyanDiveLeft: hydrateWhenVisible(() => import('~/components/svg/new/clouds/CloudCyanDiveLeft')),
    CloudCyanDiveRight: hydrateWhenVisible(() => import('~/components/svg/new/clouds/CloudCyanDiveRight')),
    TwitterLogo: hydrateWhenIdle(() => import('~/components/svg/new/TwitterLogo'))
  },
  layout: 'new',
  async fetch({ store, error, route }) {
    try {
      const pageName = route.name.split('___')
      const seoData = store.getters['seos/seosByUri'](pageName[0])
      if (seoData === null) {
        await store.dispatch('seos/fetchSeos', {
          uri: route.name
        })
      }
    } catch (err) {
      error(err)
    }
  },
  head({ $config: { appUrl } }) {
    return {
      title: this.meta ? this.meta.title : this.$t('common.meta_title_default'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.meta ? this.meta.description : this.$t('common.meta_desciption_default')
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: this.meta ? this.meta.description : this.$t('common.meta_desciption_default')
        },
        {
          hid: 'og-title',
          name: 'og:title',
          content: this.meta
            ? this.meta.title
            : this.$t('common.meta_title_default')
        },
        {
          hid: 'og-image',
          name: 'og:image',
          content: this.meta
            ? this.meta.image
            : appUrl + 'seo/default.jpg'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.meta ? this.meta.keyword : ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['darkTheme']),
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('seos', [
      'contacts'
    ]),
    ...mapGetters('seos', [
      'seosByUri'
    ]),
    meta() {
      const pageName = this.$nuxt.$route.name.split('___')
      return this.seosByUri(pageName[0])
    }
  },
  methods: {
    ...mapActions('modal', [
      'changeModalWalletState'
    ]),
    async openModalWallets() {
      await this.changeModalWalletState({
        state: true
      })
    }
  }
}
</script>

<style scoped>
  @import url('../assets/css/new/main.css');
</style>
